























































































































































import { Component, Vue } from "vue-property-decorator";
import OrderStatusLong from "@/views/mine/components/OrderStatusLong.vue";
import { MineApi, OrderResult } from "@/api/mine.api";
import OrderCard from "@/views/mine/components/OrderCard.vue";
import { copy, getNow, momentFormat, observer } from "@/utils/common.util";
import { Toast } from "vant";
import EmptyBox from "@/components/EmptyBox.vue";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { EmptyBox, OrderCard, OrderStatusLong },
})
export default class MyOrderDetail extends Vue {
  order_no = this.$route.params.order_no;
  item: OrderResult | null = null;
  error = false;
  paymethod = ''
  handleOpenAddressDetail(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-delivery-record/${-1}?all=${JSON.stringify(this.item)}`);
    }
  
    this.$router.push({
      name: "MyDeliveryRecordDetail",
      params: {  id: "-1" },
      query: { all:((this.item as any) || {}),time: String(getNow()) },
    });
  }
  toMySupply() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysupply`);
    }
    this.$router.push({ name: "mysupply" });
  }
  get exchange_price(): string {
    const { product_total_price = 0, order_price = 0 } = this.item || {};
    return String((product_total_price * 1000 - order_price * 1000) / 1000);
  }
  get IsAppState() {
    return InteractionLib.isApp();
  }
  get createTime(): string {
    return momentFormat(this.item?.create_time);
  }

  get payTime(): string {
    return momentFormat(this.item?.pay_time);
  }

  mounted(): void {
    observer("refreshInit", () => this.getOrderDetail());
    this.getOrderDetail();
  }

  handleCopy(str: string): void {
    copy(str);
    Toast.success("已复制");
  }

  async deleteOrder() {
    if (await MineApi.deleteOrder(this.order_no)) {
      this.$router.back();
      return;
    }
    Toast("删除失败");
  }

  async getOrderDetail() {
    this.item = await MineApi.getOrderDetail(this.order_no).catch(() => {
      this.error = true;
      return null;
    });
    if(this.item?.pay_channel == 0){
      this.paymethod = '瓶盖支付'
    }
    else if(this.item?.pay_channel == 1){
      this.paymethod = '支付宝'
    }else if(this.item?.pay_channel == 2||this.item?.pay_channel == 3){
      this.paymethod = '微信'
    }
    else if(this.item?.pay_channel == 4){
      this.paymethod = '钱包'
    }
    
  }
  tohome() {
    if (InteractionLib.isFlutter()) {
      return InteractionLib.router(18,{tab:2})
    }
    this.$router.push({ path: "/" });
  }
  toSupply() {
    this.$router.push({ path: "/my-voucher" });
  }
  toAsk(){
    window.open("https://support.qq.com/products/373434")
  }
  toService(){
    window.open("https://work.weixin.qq.com/kfid/kfc3cc5741444048d77")
  }
}
