
















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Icon } from "vant";

@Component({
  components: {
    Icon,
  },
})
export default class OrderStatusLong extends Vue {
  @Prop() private readonly title!: string;
  @Prop() private readonly desc!: string;
  @Prop() private readonly address_id!: number;
  @Prop() private readonly index!: number;

  @Emit()
  handleClickAddress(): void {
    return;
  }

  get rootStyle(): Record<string, string> {
    return {
      background: this.color,
    };
  }

  get color(): string {
    const { index = 0 } = this;
    if (index >= 3 || index === 0)
      return "linear-gradient(135deg, #fa5e41 0%, #f43041 100%)";
    if (index === 1) return "linear-gradient(135deg, #31C367 0%, #31C184 100%)";
    return "linear-gradient(135deg, #22294E 0%, #0C1128 100%)";
  }

  get icon(): string {
    const { index = 0 } = this;
    if (index >= 3 || index === 0) return "__order-cancel-icon";
    if (index === 1) return "__order-wait-icon";
    return "__order-complete-icon";
  }
}
